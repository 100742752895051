.Marker {
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.Marker_Large {
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 80%;
  cursor: pointer;
}
